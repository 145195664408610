<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
            Let's answer some questions
          </h2>

          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                placeholder="Search faq..."
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >

        <b-tab
          v-for="(categoryObj, categoryName, index) in filteredData"
          :key="categoryName"
          :active="!index"
        >
          <template #title>
            <feather-icon
              :icon="categoryObj.icon"
              size="18"
              class="mr-1"
            />
            <span class="font-weight-bold">{{ categoryObj.title }}</span>
          </template>

          <faq-question-answer :options="categoryObj" />
        </b-tab>
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <!--<section class="faq-contact">
      <b-row class="mt-3 pt-75 text-center">
        <b-col cols="12">
          <h2>You still have a question?</h2>
          <b-card-text class="mb-3">
            If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly!
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="PhoneCallIcon"
                size="18"
              />
            </b-avatar>
            <h4>843-991-7557</h4>
            <span class="text-body">We are always happy to help!</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MailIcon"
                size="18"
              />
            </b-avatar>
            <h4>titleist123@gmail.com</h4>
            <span class="text-body">Best way to get answer faster!</span>
          </b-card>
        </b-col>
      </b-row>
    </section>-->
    <!--/ contact us -->
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
} from 'bootstrap-vue'
import FaqQuestionAnswer from './Components/FaqQuestionAnswer.vue'

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqQuestionAnswer,
  },
  data() {
    return {
      faqSearchQuery: '',
      faqData: [
        {
          icon: 'InfoIcon',
          title: 'Questions',
          subtitle: '',
          qandA: [
            {
              question: 'WHAT IS THE INDUSTRY STANDARD GRATUITY FOR THE CREW?',
              answer: 'The industry standard is 20% or more, depending on how courteous you feel they were or how hard you feel they worked. However, we do not want you to feel obligated to tip anyone any amount you don’t feel they worked hard enough to earn. Pay all monies to the Captain. He will split the gratuity 50 / 50 with the Mate.',
            },
            {
              question: 'WHAT IS DEEP-SEA FISHING LIKE IN CHARLESTON?',
              answer: 'There are basically two different kinds of fishing in Charleston. TROLLING and BOTTOM FISHING.<br><br> <strong>TROLLING</strong> is fishing with the boat in motion, dragging baited lines on top of the water behind the boat. When trolling, we are targeting large species like Mahi (aka Dolphin or Dorado), Tuna, Wahoo, Sailfish and Marlin. Since we are using large bait and targeting large fish near the surface of the water, typically we can go quite a few minutes without catching anything. However, when a fish does hit, it is usually a good fight with a pretty large fish, sometimes a spectacular fight with the fish taking to the air.<br><br> <strong>BOTTOM FISHING</strong> is almost the exact opposite. When bottom fishing the boat is not in motion. We hover over a reef and drop baited lines to the bottom of the ocean. Almost as soon as bait hits the bottom, a fish will hit the line. You are constantly catching fish bottom fishing. However, the fish you are catching are much much smaller, usually two, three, or four pound fish like Snapper, Grouper, Triggerfish, Black Sea Bass, etc. That is why the full day trip is so popular; because it gives you time to do plenty of trolling for the big fish and still leave plenty of time to pull up bottom dwellers to fill up your cooler.',
            },
            {
              question: 'WHEN IS THE BEST, WORST AND AVERAGE TIME TO GO DEEP SEA FISHING IN CHARLESTON?',
              answer: 'Interestingly, most months are fairly similar for deep sea fishing here. Our standard, run-of-the-mill day, is very similar throughout the year. In a standard day of trolling for example, on average, 10 fish will hit a line. Usually, we will get 6 of those fish in the boat (4 of them will short strike, break the line or spit the hook) and four of those 6 will be edible (the other two will be fish you don’t want to eat like Barracuda or Amberjack). Again, that is just an AVERAGE day of trolling, and that is typical year round.<br><br> The only POSITIVE exception to that norm is from around mid April through roughly the end of June when we have our Mahi Mahi run. During this time, that average day is still pretty much the same as mentioned above. However, during this season we MIGHT find a school of Mahi and catch an enormous amount, often catching our limit of 60 Mahi. That’s an incredible amount of fish. Again, this doesn’t happen often, but when it does it is an epic experience. Our average day of Bottom Fishing is almost always the same. We catch plenty of small fish bottom fishing year round.<br><br> The only NEGATIVE exception to our standard fishing is January and February. That is why we close those two months and do our maintenance then.',
            },
            {
              question: 'WHAT KINDS OF FISH DO YOU CATCH DURING WHAT MONTHS?',
              answer: 'As mentioned above, our average trolling day is pretty similar year round. What changes are the species. From March 1 through May 1, Blackfin Tuna and Wahoo are most prevalent. Then, as the water heats up in late April, the Mahi start rolling in and the Tuna and Wahoo slow down. Around mid May, the mighty Billfish (Blue Marlin, White Marlin and Sailfish) roll in. Usually by July 1 the Mahi have continued their trek north so they are less bountiful, but the Billfishing is still VERY strong. Once the water starts cooling off in August and September, the Wahoo and Blackfin Tuna bite starts picking back up and stays strong till the end of December.<br><br> Bottom Fishing is incredibly strong here throughout the year with Grouper, Snapper, Triggerfish, Black Sea Bass, etc, with the exception of the Grouper season being closed January through April 30.',
            },
            {
              question: 'WHAT SHOULD I BRING WITH ME DEEP SEA FISHING?',
              answer: 'Bring whatever you are going to want to eat or drink, and pack them in your own coolers with ice. The boats are plenty big so you can bring whatever coolers you want to bring and put them on the boat with you so you know where everything is. Any food and beverages are allowed, just no glass bottles please. Hint: most people don’t eat or drink NEARLY as much as they think they will.<br><br> You will likely want a hat, sunglasses, sunscreen, camera, and appropriate clothes for the weather. Make sure to have cooler space and ice for your catch to take home with you!',
            },
            {
              question: 'DO I HAVE TO CHARTER THE ENTIRE BOAT OR CAN I JUST JOIN ANOTHER GROUP?',
              answer: 'We do not put groups together. Entirely too much can go wrong offshore. For example, if we put you with another group and they get really sick and have to come back, it ruins your day. Or, if we hook up with a giant Blue Marlin, who decides who gets to fight it? Or, what if the other people are simply annoying (what if YOU are 🤣)? No, we have found that putting groups together is a rotten idea. You charter the entire boat for yourself. If you only have one or two people and you’d like us to supply extra crew, let us know. We likely could get and aspiring new mate to come along to help you fight fish.',
            },
            {
              question: 'SHOULD I CONTACT YOU PRIOR TO OUR BOOKED DATE AND CONFIRM WE ARE ON THE SCHEDULE?',
              answer: 'A giant ABSOLUTELY. Just because you got an email confirmation, don’t assume anything. The Captain assigned to your trip should text or call you by the evening prior to your trip to confirm the departure time and that the forecast is acceptable. If you do not get a text or call from your captain by 9 pm the night before, call or text your captain just to be safe. The contact info is inside your portal and in your confirmation email. Alternatively, after you book your trip you can confirm your booking at the link below by entering your last name or Booking #. <br><br> <a href="/check-my-charter">Check Your Charter Here</a>',
            },
          ]
        },
      ],
    }
  },
  computed: {
    filteredData() {
      if (!this.faqSearchQuery) return this.faqData

      const filteredData = []
      this.faqData.map(data => {
        const filteredQandA = data.qandA.filter(qandA => qandA.question.toLowerCase().includes(this.faqSearchQuery))
        if (filteredQandA.length) filteredData.push({
          ...data,
          qandA: filteredQandA
        })
      })

      return filteredData
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
